import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Getting Started",
  "type": "Page",
  "icon": "fileDocumentLine"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Kinty UI is the Kinty's Design System React Component Library.
It is based on `}<a parentName="p" {...{
        "href": "https://www.whiteui.store/iofinance"
      }}>{`IOFinance UI Kit`}</a>{` and is distributed
as a npm package.`}</p>
    <h2>{`Installing the package`}</h2>
    <p>{`The package is available on the GitHub Package registry as a private npm package.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ npm install @kinty-app/ui
`}</code></pre>
    <h2>{`Add fonts`}</h2>
    <p>{`You need to add `}<a parentName="p" {...{
        "href": "https://fonts.google.com/specimen/Roboto"
      }}>{`Roboto`}</a>{` to your project.`}</p>
    <h2>{`Add theme provider`}</h2>
    <p>{`In your application you need to add Theme Provider component.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { ThemeProvider, Card, Heading } from "@kinty-app/ui";
import "@kinty-app/ui/index.css";

const App = () => (
  <ThemeProvider>
    <Card>
      <Heading level={1}>Hello World</Heading>
    </Card>
  </ThemeProvider>
);
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      